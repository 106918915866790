// import TeamMembers from './TeamMembers';

const Home = () => (
  <>
    HOMEPAGE
    {/* <TeamMembers /> */}
  </>
);

export default Home;
